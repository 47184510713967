<template>
  <div class="container">
    <div class="row" style="padding: 10px">
      <h5><b class="text-danger text-center">Solusi Lainnya</b></h5>
    </div>

    <div class="row" style="padding: 10px; padding-bottom: 30px">
      <h3 class="text-center">Kebijakan Privasi</h3>
      <p>
        Selamat datang di <b>AMA Group</b> (“kami”, “kita”, atau “milik kami”).
        Kami berkomitmen untuk melindungi dan menghormati privasi Anda.
        Kebijakan Privasi ini menjelaskan bagaimana kami mengumpulkan,
        menggunakan, menyimpan, dan melindungi informasi pribadi Anda.
      </p>

      <h5>1. Informasi yang Kami Kumpulkan</h5>
      <ul>
        <li>
          <b>Informasi Pribadi:</b> Nama, alamat email, nomor telepon, dan data
          lain yang Anda berikan saat mendaftar atau menggunakan layanan kami.
        </li>
        <li>
          <b>Data Penggunaan:</b> Informasi tentang bagaimana Anda mengakses dan
          menggunakan aplikasi/website kami, seperti alamat IP, jenis perangkat,
          browser, halaman yang diakses, waktu dan tanggal akses.
        </li>
        <li>
          <b>Data Lokasi (Opsional):</b> Jika Anda memberikan izin, kami dapat
          mengumpulkan data lokasi untuk meningkatkan pengalaman layanan.
        </li>
      </ul>

      <h5>2. Cara Kami Menggunakan Informasi Anda</h5>
      <ul>
        <li>Menyediakan, mengoperasikan, dan meningkatkan layanan kami.</li>
        <li>
          Menghubungi Anda terkait pembaruan, penawaran, atau layanan pelanggan.
        </li>
        <li>
          Menganalisis penggunaan layanan guna meningkatkan kualitas dan fitur
          aplikasi/website kami.
        </li>
        <li>Mematuhi kewajiban hukum yang berlaku.</li>
      </ul>

      <h5>3. Penyimpanan dan Keamanan Data</h5>
      <p>
        Kami menyimpan data pribadi Anda dengan aman dan hanya selama diperlukan
        untuk tujuan yang dijelaskan dalam kebijakan ini. Kami menerapkan
        langkah-langkah keamanan teknis dan organisasi yang sesuai untuk
        melindungi data pribadi dari akses yang tidak sah, pengungkapan, atau
        kerusakan.
      </p>

      <h5>4. Berbagi Informasi kepada Pihak Ketiga</h5>
      <p>
        Kami tidak menjual, menyewakan, atau memperdagangkan data pribadi Anda
        kepada pihak ketiga. Kami hanya akan membagikan informasi Anda dalam
        kondisi berikut:
      </p>
      <ul>
        <li>Dengan persetujuan Anda.</li>
        <li>Untuk mematuhi hukum atau perintah pengadilan.</li>
        <li>
          Dengan penyedia layanan yang membantu kami dalam mengelola layanan
          kami (dengan kewajiban menjaga kerahasiaan data).
        </li>
      </ul>

      <h5>5. Cookie dan Teknologi Pelacakan</h5>
      <p>
        Kami dapat menggunakan cookie dan teknologi serupa untuk meningkatkan
        pengalaman Anda di layanan kami. Anda dapat mengatur browser Anda untuk
        menolak semua atau sebagian cookie, atau memberi tahu Anda ketika cookie
        dikirim.
      </p>

      <h5>6. Tautan ke Situs Pihak Ketiga</h5>
      <p>
        Layanan kami dapat berisi tautan ke situs pihak ketiga. Kami tidak
        bertanggung jawab atas kebijakan privasi mereka. Kami menyarankan Anda
        untuk membaca kebijakan privasi setiap situs yang Anda kunjungi.
      </p>

      <h5>8. Perubahan Kebijakan Privasi Ini</h5>
      <p>
        Kami dapat memperbarui Kebijakan Privasi ini dari waktu ke waktu. Setiap
        perubahan akan kami umumkan melalui layanan kami atau melalui email.
        Kami mendorong Anda untuk meninjau kebijakan ini secara berkala.
      </p>

      <!-- <ul>
        <li><b>Email:</b> [alamat email]</li>
        <li><b>Telepon:</b> [nomor telepon]</li>
        <li><b>Alamat:</b> [alamat kantor]</li>
      </ul> -->
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "privacy",
  components: {
    // CardProduct,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
  
  <style scoped>
h5 {
  margin-top: 20px;
}
ul {
  padding-left: 20px;
}
p {
  margin: 10px 0;
}
li {
  color: black;
}
</style>
  